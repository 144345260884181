import { getSticker } from '@/services/chat';
import { useModel } from '@umijs/max';
import { useMemoizedFn } from 'ahooks';

import { useEffect, useState } from 'react';
const arr = [
  {
    id: '6a39728c5ac01f56d24f78739b11b9d2',
    title: 'jeonghan-angry-censored-mouth',
  },
  {
    id: 'b711d2aa15247a4f1dcd3be876701206',
    title: 'jeonghan-eyes-closed-head-tilt-sigh',
  },
  {
    id: '5380de4d1af86ec798e22fe08c65da51',
    title: 'jeonghan-playful-smirk-hand-covering-mouth',
  },
  {
    id: 'cb3b64f13fcb67eab9e062774a64e26d',
    title: 'jeonghan-heart-hands-face-close-up',
  },
  {
    id: 'afd2a62be5c95b1acca862722ff03e86',
    title: 'jeonghan-disgusted-expression-frown',
  },
  {
    id: 'cc8b4c565fae1f3b9ce21e9fa6e05876',
    title: 'jeonghan-playful-shy-smile-head-down',
  },
  {
    id: '6544318675fc79a7ef7bb0352f62283e',
    title: 'jeonghan-angry-headphone-shout',
  },
  {
    id: 'fff77797af3ae3e991e53f3c3c7f4835',
    title: 'jeonghan-blonde-wink-blow-kiss',
  },
  {
    id: '2fceb84fae2383b58286a1004adcaddc',
    title: 'jeonghan-annoyed-frustration-face-scrunched',
  },
  {
    id: 'a52dc068880ef8c041ebc2fa264a9f73',
    title: 'jeonghan-innocent-big-eyes-lips-pursed',
  },
  {
    id: 'd59af3b216a6fedf2e8853e4052d2f79',
    title: 'jeonghan-sunlit-elf-blue-clothes-head-tilt',
  },
  {
    id: '36c62c74ea83f4914d7b959672c5a864',
    title: 'jeonghan-red-hair-under-blanket-smile',
  },
  {
    id: 'c0dd7a3f9ce7b56cb962eb8c6c6d469a',
    title: 'jeonghan-lost-focus-waving-fists',
  },
  {
    id: '200c16c0888c333bb0fb0cd98d19abd7',
    title: 'jeonghan-confused-eyes-darting',
  },
  {
    id: 'a72525445635d3c185c2bed74f784463',
    title: 'jeonghan-grey-hair-rose-in-mouth',
  },
  {
    id: '48e31c84aa51f8a9b138b222938c4bf4',
    title: 'jeonghan-punch-imitation',
  },
  {
    id: '7ae27979ca0f74e9ed4f7b0f39958096',
    title: 'jeonghan-drinking-surprised-cough',
  },
  {
    id: 'c606922a5796cb4cdbde0ae1221642b2',
    title: 'jeonghan-begging-regret-shake-shoulders',
  },
  {
    id: '144669eb79d1517d0a4977fcd126c1bd',
    title: 'jeonghan-black-hair-kiss-and-heart-cheek',
  },
  {
    id: 'bbee681533f42da2dd4a288145ba0d15',
    title: 'jeonghan-laying-grass-hands-under-chin',
  },
  {
    id: 'f809119e495efbe25e60abc8426f6f4a',
    title: 'jeonghan-holding-hair-curly-smile',
  },
  {
    id: '99e91be84b25854e7ac361854512a98e',
    title: 'jeonghan-curly-hair-blank-stare-down',
  },
  {
    id: 'ea38808435b963a64c8b543f7fcbe17c',
    title: 'jeonghan-determined-pursed-lips',
  },
  {
    id: 'd6f4635c5a19ef313c86b610eaf72c4c',
    title: 'jeonghan-wide-eyes-triangle-mouth-surprised',
  },
  {
    id: '4affdba5955463bf753d1828df5af245',
    title: 'jeonghan-eyebrow-raise-open-mouth-surprised',
  },
  {
    id: 'f01b1b8b9d4bcb406d524f08f230d885',
    title: 'jeonghan-tired-eyes-drooping',
  },
  {
    id: '598f09370020c5930f3a303189d88e1c',
    title: 'jeonghan-arrogant-expression-wide-mouth',
  },
  {
    id: 'd8854a3118bc34a76d95123cbbccc9f5',
    title: 'jeonghan-blonde-short-hair-white-clothes-side-look',
  },
  {
    id: 'ad31655f1a13a47fccbbce218f5a6142',
    title: 'jeonghan-silver-short-hair-slight-smile',
  },
  {
    id: '8bfea413d877e90d18cf4369c161aa26',
    title: 'jeonghan-suit-exhausted-text-overlay',
  },
  {
    id: '90e79ff947c3952c720091b43bb53b42',
    title: 'jeonghan-gold-long-hair-hand-on-forehead',
  },
  {
    id: 'f6a09f324fd044b9e90e8b3e892dbeab',
    title: 'jeonghan-peeking-smile-inquisitive',
  },
  {
    id: 'd0b7fef3e3bb6de9291b2db27360d0a8',
    title: 'jeonghan-slight-frown-disapproval',
  },
  {
    id: '7c742fafebfd85ee6e4763bff038117d',
    title: 'jeonghan-yellow-outfit-lemon-background-text',
  },
  {
    id: 'ffbf07c4c03568bacb85ced6d2face14',
    title: 'jeonghan-white-sweater-blonde-hair-hand-covering-mouth',
  },
  {
    id: 'b2a444acc86353add34e76c2fa7ba951',
    title: 'jeonghan-awkward-smile-big-eyes',
  },
  {
    id: '4fda9f8482d104ae919caf5519bd975a',
    title: 'jeonghan-pouting-gold-hair',
  },
  {
    id: '9976a7368e864c42b5f67086433e6e06',
    title: 'jeonghan-squinting-crossed-fists-text-overlay',
  },
  {
    id: '8fea494422305f7c7e22ad95d8a93098',
    title: 'jeonghan-black-clothes-smirk-tongue-text-overlay',
  },
];

export default function useEmojis() {
  const { initialState } = useModel('@@initialState');
  const [sticker, setSticker] = useState([]);

  // 定义手动触发的表情包获取函数
  const fetchSticker = useMemoizedFn(async () => {
    // 如果已经有数据，不再请求
    if (sticker && sticker.length > 0) return;

    try {
      const res = await getSticker();
      setSticker(res?.data || []); // 将数据保存到全局状态中
      // setSticker(arr);
    } catch (error) {
      console.error('sticker error', error);
    }
  });

  useEffect(() => {
    if (initialState?.currentUser?.id) {
      fetchSticker();
    }
  }, [fetchSticker, initialState?.currentUser?.id]);

  return {
    sticker,
    fetchSticker,
  };
}
