import { request } from '@umijs/max';

/** live2d点击交互 */
export async function clickLive2d(body?: any) {
  return request('/api/v1/event/click_feedback', {
    method: 'POST',
    data: body,
  });
}

/** 保存live2d服装 */
export async function saveLive2dCostumes(body?: any) {
  return request('/api/v1/live2d/saveUserDress', {
    method: 'POST',
    data: body,
  });
}

/** 获取live2d服装参数 */
export async function getAllParameters(params?: any) {
  return request('/api/v1/live2d/getAllParameters', {
    method: 'GET',
    params: {
      ...params,
    },
  });
}

/** 获取live2d服装 */
export async function getLive2dCostumes(params?: any) {
  return request('/api/v1/live2d/getUserDress', {
    method: 'GET',
    params: {
      ...params,
    },
  });
}
