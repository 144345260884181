import { getLive2dCostumes } from '@/services/live2d';
import { useMemoizedFn } from 'ahooks';
import type { Live2DModel } from 'pixi-live2d-display/cubism4';
import { useMemo, useRef, useState } from 'react';

// 类型定义
export interface ModelConfig {
  path: string;
  name: string;
}

interface CostumeParameter {
  id: string;
  type: string;
  value: number;
}

interface ModelParameter {
  id: string;
  type: string;
  isActive: boolean;
}

type InitializeCostumesType = () => Promise<void>;

/** 将服装数据转换为参数记录 */
const transformCostumesToParameters = (costumes: CostumeParameter[]): Record<string, ModelParameter> => {
  return costumes.reduce(
    (acc, param) => ({
      ...acc,
      [param.id]: {
        id: param.id,
        type: param.type,
        isActive: true,
      },
    }),
    {},
  );
};

/** 全局live2d模型 */
export default () => {
  // 当前使用的模型
  const [currentModel, setCurrentModel] = useState<Live2DModel | null>(null);
  // live2d模式
  const [live2dMode, setLive2dMode] = useState<string>('default');
  // 模型动作
  const [motion, setMotion] = useState<string | null>(null);
  // 激活的模型服装id
  const [parameterIds, setParameterIds] = useState<string[]>([]);
  // 存储初始参数
  const [initialParameters, setInitialParameters] = useState<Record<string, ModelParameter>>({});
  // 模型参数
  const [parameters, setParameters] = useState<Record<string, ModelParameter>>({});
  // accompany场景下，点击节流
  const [lock, setStateLock] = useState(false);

  const timerId = useRef<NodeJS.Timeout | null>(null);
  /** 初始化或刷新模型服装 */
  const initializeCostumes: InitializeCostumesType = useMemoizedFn(async () => {
    try {
      const res = await getLive2dCostumes();
      if (res?.data?.parameters_id && Array.isArray(res?.data?.parameters_id)) {
        const initialParams = transformCostumesToParameters(res.data.parameters_id);
        setParameters(initialParams);
        setInitialParameters(initialParams);
      } else {
        setParameters({});
        setInitialParameters({});
      }
    } catch (error) {
      console.error('获取live2d服装数据:', error);
    }
  });

  /** 检查是否有未保存的修改 */
  const hasUnsavedChanges = useMemo(() => {
    // 1. 检查 parameters 中的所有键
    const hasChangesInParameters = Object.keys(parameters).some((key) => {
      const current = parameters[key];
      const initial = initialParameters[key];

      // 新增的参数
      if (!initial) return true;

      // 修改的参数
      return current.isActive !== initial.isActive || current.type !== initial.type || current.id !== initial.id;
    });

    // 2. 检查 initialParameters 中的键是否在 parameters 中都存在
    const hasDeletedParameters = Object.keys(initialParameters).some((key) => !parameters[key]);

    return hasChangesInParameters || hasDeletedParameters;
  }, [parameters, initialParameters]);

  // useEffect(() => {
  //   if (initialState?.currentUser?.id) {
  //     initializeCostumes();
  //   }
  // }, [initialState?.currentUser?.id, initializeCostumes]);

  const setLock = useMemoizedFn((value: boolean) => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }

    setStateLock(value);
    timerId.current = setTimeout(() => {
      setStateLock(false);
    }, 5000);
  });

  return {
    motion,
    setMotion,
    parameters,
    setParameters,
    currentModel,
    setCurrentModel,
    live2dMode,
    setLive2dMode,
    hasUnsavedChanges,
    setInitialParameters,
    parameterIds,
    setParameterIds,
    refreshCostumes: initializeCostumes,
    lock,
    setLock,
  };
};
