import { getCharacterList } from '@/services/character';
import { useMemoizedFn } from 'ahooks';
import { useState } from 'react';

export type AnimalItemType = {
  /** 角色id */
  id: string;
  /** 角色名称 */
  name: string;
  /** 头像，用作loadingV2 */
  avatar: string;
  description: string;
  author_id: string;
  public: boolean;
  user_default_nickname: string;
  updated_date: Date;
  created_date: Date;
  /** 图片 */
  cover_image: string;
  /** json文件地址 */
  live2d_url: string;
  /** loading图片地址 */
  live2d_loading: string;
};

/** 动物列表(用于角色切换) */
export default function useAnimalList() {
  const [animalList, setAnimalList] = useState<Array<AnimalItemType>>([]);

  const resetAnimalList = useMemoizedFn(() => {
    if (animalList.length !== 0) return;
    getCharacterList().then((res) => {
      setAnimalList(res.data);
    });
  });

  return {
    animalList,
    resetAnimalList,
  };
}
