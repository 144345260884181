import { DefaultCharacterType, ReturnDataType } from '@/types/response';
import { request } from '@umijs/max';
/** 获取默认角色 */
export async function getCharacter(params?: any) {
  return request<ReturnDataType<DefaultCharacterType>>('/api/v1/character/my/default', {
    method: 'GET',
    params: {
      ...params,
    },
  });
}

/** 获取角色列表 */
export async function getCharacterList(params?: any) {
  return request('/api/v1/character', {
    method: 'GET',
    params: {
      ...params,
    },
  });
}

/** 修改昵称patch请求 */
export async function patchCharacter(params?: any) {
  return request(`/api/v1/character/my/${params.user_character_id}`, {
    method: 'PATCH',
    data: {
      character_call: params.character_call,
    },
  });
}

/** 切换角色 */
export async function switchCharacter(params?: any) {
  return request(`/api/v1/character/switch/${params.character_id}`, {
    method: 'POST',
  });
}
