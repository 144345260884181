import { getCharacter } from '@/services/character';
import { DefaultCharacterType } from '@/types/response';
import { useModel } from '@umijs/max';
import { useMemoizedFn } from 'ahooks';
import { useMemo, useState } from 'react';
import { AnimalItemType } from './animalList';

/** 当前用户 */
export default function useCurrentUserInfo() {
  const { animalList } = useModel('animalList') as { animalList: AnimalItemType[] };
  const [currentUser, setCurrentUser] = useState<DefaultCharacterType>();

  /** 重新获取角色信息 */
  const resetUserInfo = useMemoizedFn(() => {
    getCharacter().then((res) => {
      setCurrentUser(res.data);
    });
  });

  const characterIndex = useMemo(() => {
    return animalList?.findIndex((item: AnimalItemType) => item.id === currentUser?.character_id);
  }, [animalList, currentUser]);

  // @ts-ignore
  const characterInfo = useMemo(() => {
    // @ts-ignore
    return animalList?.[characterIndex];
  }, [animalList, characterIndex]);

  return {
    currentUser,
    setCurrentUser,
    /** 重新获取角色id */
    resetUserInfo,
    /** 当前角色对应相关的信息 */
    characterInfo: characterInfo as AnimalItemType,
    /** 对应第几个index */
    characterIndex,
  };
}
