// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import routeProps from './routeProps';

if (process.env.NODE_ENV === 'development') {
  Object.entries(routeProps).forEach(([key, value]) => {
    const internalProps = ['path', 'id', 'parentId', 'isLayout', 'isWrapper', 'layout', 'clientLoader'];
    Object.keys(value).forEach((prop) => {
      if (internalProps.includes(prop)) {
        throw new Error(
          `[UmiJS] route '${key}' should not have '${prop}' prop, please remove this property in 'routeProps'.`
        )
      }
    })
  })
}

import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","redirect":"/home","parentId":"@@/global-layout","id":"1"},"2":{"path":"personal","parentId":"@@/global-layout","id":"2"},"3":{"path":"login","parentId":"@@/global-layout","id":"3"},"4":{"path":"home","parentId":"@@/global-layout","id":"4"},"5":{"path":"costumes","parentId":"@@/global-layout","id":"5"},"6":{"path":"chat","parentId":"@@/global-layout","id":"6"},"7":{"path":"afterlogin","parentId":"@@/global-layout","id":"7"},"8":{"path":"accompanying","parentId":"@@/global-layout","id":"8"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import('./EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__Personal__index" */'/kaniko/tmp/workspace/src/pages/Personal/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__Login__index" */'/kaniko/tmp/workspace/src/pages/Login/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__Home__index" */'/kaniko/tmp/workspace/src/pages/Home/index.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__Costumes__index" */'/kaniko/tmp/workspace/src/pages/Costumes/index.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__Chat__index" */'/kaniko/tmp/workspace/src/pages/Chat/index.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__AfterLogin__index" */'/kaniko/tmp/workspace/src/pages/AfterLogin/index.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__Accompanying__index" */'/kaniko/tmp/workspace/src/pages/Accompanying/index.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/kaniko/tmp/workspace/src/layouts/index.tsx')),
},
  };
}
