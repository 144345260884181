import { request } from '@umijs/max';

/** 登录 */
export async function login(body: any) {
  return request('/api/v1/auth/phone', {
    method: 'POST',
    data: body,
  });
}

/** 发送验证码 */
export async function sendCode(body: any) {
  return request('/api/v1/auth/phone/code', {
    method: 'POST',
    data: body,
  });
}

/** 阿里云验证码 */
export async function captcha(body: any) {
  return request('/api/v1/auth/captcha', {
    method: 'POST',
    data: body,
  });
}

/** 用户信息 */
export async function getUser(params?: any) {
  return request('/api/v1/user', {
    method: 'GET',
    params: {
      ...params,
    },
  });
}

/** 初始化用户信息 */
export async function initUser(body?: any) {
  return request('/api/v1/user/init', {
    method: 'POST',
    data: body,
  });
}

/** 个人中心 */
export async function getUserCenter(params?: any) {
  return request('/api/v1/user/info', {
    method: 'GET',
    params: {
      ...params,
    },
  });
}

/** 购买记录 */
export async function getPurchaseRecord(params?: any) {
  return request('/api/v1/wallet/payment/user/purchases', {
    method: 'GET',
    params: {
      ...params,
    },
  });
}

/** 获取mqtt关于用户的配置 */
export async function getMqttConfig(params?: any) {
  return request('/api/v1/auth/mqtt/config', {
    method: 'GET',
    params: {
      ...params,
    },
  });
}

